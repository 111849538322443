<template>
  <div class="order-list-wrapper" v-loading="activeName === '2' ? addOrderLoading : false"
    :element-loading-text="'企业当前正在执行【导入订单并发货】...' + progress" element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.4)">
    <en-table-layout ref="enTableLayout" :tableData="activeName === '1' ? tableData : tableDataTwo" :loading="loading"
      :span-method="orderSpanMethod" @selection-change="orderListSelectionChange" @select-all="orderListSelectionAll" @select="selectionCheckOne">
      <template slot="header">
        <el-tabs v-model="activeName" type="card" v-if="shopInfo.shop_type === 3" @tab-click="handleClick">
          <el-tab-pane label="商家订单列表" name="1"></el-tab-pane>
          <el-tab-pane label="自有商品订单列表" name="2" v-if="hasOwnGoods"></el-tab-pane>
        </el-tabs>
      </template>

      <template slot="toolbar">
        <el-form-item label="订单状态" class="col-auto">
          <el-select style="width: 120px;" v-model="params.order_status" size="medium" clearable>
            <!-- 企业端 -->
            <template v-if="shopInfo.shop_type === 3">
              <el-option v-for="item in ownOrderStatus[activeName === '1' ? 0 : 1]" :key="item.value"
                :label="item.label" :value="item.value" />
            </template>
            <!-- 现金商城站点 -->
            <template v-else>
              <el-option v-for="item in MixinOrderStatusOptions[0]" :key="item.value" :label="item.label"
                :value="item.value" />
            </template>
          </el-select>
        </el-form-item>
        <el-form-item label="物流状态" class="col-auto">
          <el-select style="width: 100px" v-model="params.logistics_status" size="small" placeholder="请选择" clearable>
            <el-option label="全部" :value="undefined" />
            <el-option label="未发货" :value="0" />
            <el-option label="拣货中" :value="1" />
            <el-option label="已发货" :value="2" />
            <el-option label="已收货" :value="3" />
          </el-select>
        </el-form-item>
        <el-form-item label="下单日期" class="col-auto">
          <el-date-picker style="width: 220px" v-model="advancedForm.order_time_range" type="daterange" align="center"
            size="medium" :editable="false" unlink-panels range-separator="-" start-placeholder="开始日期"
            end-placeholder="结束日期"></el-date-picker>
        </el-form-item>
        <el-form-item label="所属公司" class="col-auto" v-if="shopInfo.shop_type == 1">
          <el-input style="width: 200px" v-model.trim="advancedForm.cash_company" placeholder="请输入"
            clearable></el-input>
        </el-form-item>
        <el-form-item label="来源应用" class="col-auto" v-if="shopInfo.shop_type == 3">
          <el-select style="width: 100px" v-model="params.mall_type" size="small" placeholder="请选择" clearable>
            <el-option v-for="item in mallTypeList" :key="item.value" :label="item.name" :value="item.value" />
          </el-select>
        </el-form-item>
        <el-form-item label="订单类型" class="col-auto" v-if="shopInfo.shop_type == 3 && activeName === '1'">
          <el-select style="width: 100px" v-model="params.trade_type" size="small" placeholder="请选择" clearable>
            <el-option label="全部" value="" />
            <el-option label="普通商品订单" :value="1" />
            <el-option label="礼包订单" :value="2" />
            <el-option label="计次卡订单" :value="3" />
            <el-option label="宅配卡订单" :value="4" />
          </el-select>
        </el-form-item>
        <el-form-item label="关键词" class="col-auto">
          <el-input style="width: 280px" v-model.trim="advancedForm.keywords" placeholder="请输入关键词" clearable>
            <el-select style="width: 130px;" v-model="search_type" slot="prepend">
              <el-option label="订单编号" value="order_sn"></el-option>
              <el-option label="下单账号" value="mobile_account"></el-option>
              <el-option label="商品名称" value="goods_name"></el-option>
              <el-option label="物流单号" value="ship_no"></el-option>
              <el-option label="收货人手机号" value="ship_mobile"></el-option>
              <el-option v-if="params.mall_type != 2 && shopInfo.shop_type !== 1" label="套餐卡号"
                value="card_code"></el-option>
              <el-option label="储值卡号" value="card_number"></el-option>
              <el-option v-if="shopInfo.shop_type !== 1" label="站点名称" value="shop_name"></el-option>
              <el-option v-if="shopInfo.shop_type !== 1" label="礼包名称" value="shopComboName"></el-option>
              <el-option v-if="shopInfo.shop_type == 3 && activeName === '2'" label="备注信息"
                value="order_remark"></el-option>
            </el-select>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button @click="advancedSearchEvent();" size="small" type="primary">搜索</el-button>
          <!--          站点导出订单-->
          <el-button @click="submitImport();" size="small" type="primary"
            v-if="shopInfo.shop_type == 1">导出订单</el-button>
            <!-- 判断是否是国联智诚企业下的【缤纷祝福】现金商城、【智橙兑换服务】的智能套餐卡商城并且是否设置挂起订单恢复方式为手动恢复 -->
            <el-button v-if="shopInfo.shop_type == 1&&showExamine" @click="orderExamine();" size="small" type="primary">订单审核</el-button>
        </el-form-item>
        <div class="col"></div>
        <!-- 企业端自有子订单导出 -->
        <div class="col-auto restyle1545">
          <el-button @click="ziyousubmitImport(1)" v-if="activeName === '2'" :loading="importLoading1" size="small"
            type="primary">导出全部</el-button>
          <el-button @click="ziyousubmitImport(0)" v-if="activeName === '2'" :loading="importLoading" size="small"
            type="primary">导出选中</el-button>
          <el-button v-if="activeName === '2'" @click="batchImport = true; funcName = 'importAndShipSellerOrder';"
            size="small" type="primary">导入订单并发货</el-button>
          <el-button v-if="activeName === '2'" @click="cancelPicking" :loading="importLoading2" size="small"
            type="primary">取消拣货</el-button>
        </div>
      </template>
      <template slot="table-columns" v-if="activeName === '1' ? tableData[0] : tableDataTwo[0]">
        <el-table-column type="selection"/>

        <el-table-column label="订单编号" prop="sn" width="180" fixed="left">
          <template slot-scope="{row}">
            <template v-if="activeName === '1'">{{ row.trade_sn }}<br/><el-tag v-if="row.activity_group_by_id" size="mini" type="danger" effect="plain">多人拼团</el-tag></template>
            <template v-else>{{ row.sn }}</template>
            <p class="order_sn_style" v-if="activeName === '2' && row.expected_deliver_date">({{
      row.expected_deliver_date |
      unixToDate('yyyy-MM-dd') }}可正常发货)
            </p>
          </template>
        </el-table-column>
        <el-table-column label="订单总额" width="100" fixed="left">
          <template slot-scope="{row}">
            <!-- 商家订单 shop_type=2 供应商端-->
            <template v-if="activeName === '1'">
              <!-- mall_type  2 企业端现金商城订单  3 企业端套餐卡订单  -->
              {{ formattedPrice(row) | unitPrice('￥') }}
            </template>
            <!-- 自有订单 -->
            <template v-else>{{ selectedPrice(row) | unitPrice('￥') }}</template>
          </template>
        </el-table-column>

        <el-table-column label="图片" width="60" class-name="goods-cover-wrapper" fixed="left">
          <template slot-scope="{row}">
            <el-popover placement="right" trigger="hover">
              <img :src="row.image || row.goods_image" style="width: 300px;" alt />
              <div slot="reference">
                <img :src="row.image || row.goods_image" class="goods-cover" alt="" />
                <img class="goods-tag" :src="getRowTagImage(row)" alt />
              </div>
            </el-popover>
          </template>
        </el-table-column>

        <el-table-column label="商品名称" prop="goods_name" show-overflow-tooltip width="200" fixed="left">
          <template slot-scope="{row}">
            {{ row.goods_name.replaceAll('amp;', '').replaceAll(' ', '&nbsp;') }}
          </template>
        </el-table-column>

        <el-table-column label="商品单价" width="100">
          <template slot-scope="{row}">
            <template v-if="activeName === '1'">
              <template v-if="shopInfo.shop_type !== 2">
                <div v-if="row.goods_type === 'NORMAL'">
                  <span>{{ row.shop_price | unitPrice('￥') }}</span>
                </div>
                <div v-else>
                  {{ row.revise_exchange_money | unitPrice('￥') }} +
                  {{ row.revise_exchange_point }}积分
                </div>
              </template>
              <template v-else>
                <div v-if="row.goods_type === 'NORMAL'">
                  <span>{{ row.supplier_price | unitPrice('￥') }}</span>
                </div>
                <div v-else>
                  {{ row.exchange_money | unitPrice('￥') }} +
                  {{ row.exchange_point }}积分
                </div>
              </template>
            </template>

            <template v-else>
              <!-- 现金 -->
              <template v-if="shopInfo.shop_type !== 2">
                <div v-if="row.goods_type === 'NORMAL'">
                  <span>{{ row.enterprise_purchase_price | unitPrice('￥') }}</span>
                </div>
                <div v-else>
                  {{ row.revise_exchange_money | unitPrice('￥') }} +
                  {{ row.revise_exchange_point }}积分
                </div>
              </template>
              <!-- 供应商 -->
              <template v-else>
                <div v-if="row.goods_type === 'NORMAL'">
                  <span>{{ row.supplier_price | unitPrice('￥') }}</span>
                </div>
                <div v-else>
                  {{ row.exchange_money | unitPrice('￥') }} +
                  {{ row.exchange_point }}积分
                </div>
              </template>
            </template>
          </template>
        </el-table-column>

        <el-table-column label="商品数量" prop="goods_num" width="80" />

        <el-table-column label="售后状态" width="80">
          <template slot-scope="{row}">{{ MixinGetOrderStatus(row.service_status)}}</template>
        </el-table-column>

        <el-table-column label="物流状态" width="80">
          <template slot-scope="{row}">
            <span v-if="row.delivery_method &&![1,4,6].includes(row.delivery_method)">未发货</span>
            <span v-else-if="row.logistics_status_text">{{ row.logistics_status_text }}</span>
            <span v-else>
              <template v-if="row.state === 0">未发货</template>
              <template v-else-if="row.state === 2">已发货</template>
              <template v-else>已收货</template>
            </span>
          </template>
        </el-table-column>

        <el-table-column label="物流单号" width="300" show-overflow-tooltip>

          <template slot-scope="{row}">
            <template v-if="row.delivery_method && [2,3,5].includes(row.delivery_method)">
              {{ row.delivery_method == 2 ? '餐类自提' : '自提' }}
            </template>
            <template v-else-if="row.delivery">{{ row.delivery.logiName }}: {{ row.delivery.deliveryNo }}</template>
          </template>
        </el-table-column>
        <el-table-column label="收货人姓名" width="100" :prop="activeName === '1' ? 'consignee_name' : 'ship_name'" />
        <el-table-column label="收货人手机号" width="120" :prop="activeName === '1' ? 'consignee_mobile' : 'ship_mobile'" />
        <el-table-column label="收货地址" width="300" show-overflow-tooltip align="center">
          <template slot-scope="{row}">
            <span v-if="activeName === '1'"> {{ formatAddress(row, 'consignee') }}</span>
            <span v-else> {{ formatAddress(row, 'ship') }}</span>
          </template>
        </el-table-column>
        <el-table-column v-if="!(shopInfo.shop_id == 1167 && parentLogin == 0)" label="供货方" width="80">
          <template slot-scope="{row}">
            <span>{{ orderSourceMap[row.order_source] }}</span>
          </template>
        </el-table-column>

        <el-table-column label="订单状态" width="100">
          <template slot-scope="{row}">
            <template v-if="activeName === '1'">
              {{ row.jd_cancel_status == 1 ? '取消中' : row.trade_status_text }}
              <div v-if="row.hangup_status === 1" style="color: red;">(店铺挂起)</div>
              <div v-if="row.hangup_status === 2" style="color: red;">(企业挂起)</div>
            </template>
            <template v-else>
              <span v-if="row.jd_cancel_status == 1">取消中</span>
              <span v-else>
                <template v-if="row.order_status_text === '待发货' && row.logistics_status === 1">拣货中</template>
                <template v-else>{{row.order_status_text}}</template>
              </span>
              <div v-if="row.hangup_status === 1" style="color: red;">(店铺挂起)</div>
              <div v-if="row.hangup_status === 2" style="color: red;">(企业挂起)</div>
            </template>
          </template>
        </el-table-column>

        <el-table-column label="来源应用" align="center" width="200">
          <template slot-scope="{row}">
            <span>{{ mallTypeNames[row.mall_type] }}</span>
          </template>
        </el-table-column>
        <el-table-column label="订单类型" prop="shop_combo_name" width="110">
          <template slot-scope="{row}">
            {{ row.trade_form | tradeformFilter }}
          </template>
        </el-table-column>

        <el-table-column label="下单时间" width="160">
          <template slot-scope="{row}">{{ row.create_time | unixToDate }}</template>
        </el-table-column>

        <el-table-column label="下单账号" prop="member_name" width="150" class-name="font-family-tttgb">
          <template slot-scope="{row}">

            <div v-if="row.mobile && row.login_account">
              <p>{{ row.mobile }}</p>
              {{ row.login_account }}
            </div>
            <div v-else>
              {{ row.mobile }}
              {{ row.login_account }}
            </div>
          </template>
        </el-table-column>

        <el-table-column label="下单备注信息" width="160">
          <template slot-scope="{row}">
            <el-tooltip class="item" effect="dark" :content="row.remark" placement="top">
              <div class="remark-box">
                {{ row.remark }}
              </div>
            </el-tooltip>
          </template>
        </el-table-column>

        <!-- 分销利润 和 商城利润 -->
        <template v-if="shopInfo.shop_type === 3 || (activeName === '2' && MixinIsFormEnterprise)">
          <el-table-column label="分销利润" width="100">
            <template slot-scope="{row}">
              <template v-if="activeName === '1'">
                <span v-if="row.shop_id == row.seller_id">￥0.00</span>
                <span v-else> {{ row.distribution_profit | unitPrice('￥') }}</span>
              </template>
              <template v-else>0</template>
            </template>
          </el-table-column>

          <el-table-column label="商城利润" width="100">
            <template slot-scope="{row}">
              <template v-if="activeName === '1'">
                <span v-if="row.mall_type == 7">￥0.00</span>
                <span v-else-if="row.shop_id == row.seller_id">￥0.00</span>
                <span v-else>{{ (row.revise_price - row.enterprise_total_price).toFixed(2) | unitPrice('￥') }}</span>
              </template>
              <template v-else>
                <span v-if="row.mall_type == 7">￥0.00</span>
                <span v-else>{{ (row.revise_price - row.enterprise_purchase_price).toFixed(2) | unitPrice('￥') }}</span>
              </template>
            </template>
          </el-table-column>
          <el-table-column v-if="activeName === '2'" label="备注信息" width="120">
            <template v-if="row.remark_update_date && row.order_remark" slot-scope="{row}">
              <el-tooltip placement="top">
                <div slot="content">
                  {{ row.remark_update_date | unixToDate }}
                  <br />
                  {{ row.order_remark.replaceAll("/n", "\n") }}
                </div>
                <div class="conceal">
                  {{ row.remark_update_date | unixToDate }}
                  <br />
                  {{ row.order_remark.replaceAll("/n", "\n") }}
                </div>
              </el-tooltip>
            </template>
          </el-table-column>
        </template>

        <el-table-column label="操作" :width="activeName === '2' ? 290 : 200" fixed="right">
          <template slot-scope="{row}">
            <div style="display: flex;justify-content: center">
              <el-button type="primary" size="small" @click="handleOperateOrder(row)">查看详情</el-button>
              <div v-if="shopInfo.shop_type == 1 && row.isCancelOrder" style="margin-left: 8px">
                <el-button type="primary" size="small" @click="cancelTradeHandle(row)">取消订单</el-button>
              </div>
              <el-button v-if="activeName === '2' && row.order_status === 'SHIPPED'" type="primary" size="small"
                @click="openLogisticsDialog(row.sn)">修改物流信息</el-button>
              <!-- && row.order_status === 'SHIPPED' -->
              <el-button v-if="activeName === '2'" type="primary" size="small"
                @click="openRemarkDialog(row)">备注</el-button>
              <el-button
                v-if="row.jd_cancel_status !== 1 && activeName === '2' && row.order_status === 'PAID_OFF' && row.ship_button"
                type="warning" size="small" @click="deliverDialog(row.sn, ship_no)">发货</el-button>
            </div>
          </template>
        </el-table-column>
      </template>

      <el-pagination slot="pagination" @size-change="handlePageSizeChange" @current-change="handlePageCurrentChange"
        :current-page="pageData.page_no" :page-size="pageData.page_size" :page-sizes="MixinPageSizes"
        :layout="MixinTableLayout" background :total="pageData.data_total" />
    </en-table-layout>

    <x-dialog :proxy="orderPublishDialog">
      <div v-if="shopInfo.shop_type !== 1">
        <en-table-layout ref="multipleTable" :table-data="sku_list" @selection-change="handleSelectionChange">
          <template slot="toolbar">
            <div class="col"></div>

            <el-button type="primary" size="small"
              :disabled="!selectList.length || orderDetail.pay_status !== 'PAY_YES'"
              @click="submitFhing">确认发货</el-button>
          </template>

          <template slot="table-columns">
            <el-table-column :selectable="checkboxT" type="selection" width="45"></el-table-column>
            <el-table-column prop="sku_sn" label="商品编号" width="150"></el-table-column>
            <el-table-column prop="name" label="商品名称以及规格" min-width="200"></el-table-column>
            <el-table-column prop="num" label="商品数量" width="100"></el-table-column>
            <el-table-column label="物流公司" width="200">
              <template slot-scope="scope">
                <el-select size="mini" filterable :remote-method="remoteMethod" :disabled="scope.row.state === 3"
                  v-model="scope.row.logi_id" @focus="remoteFocus" placeholder="请选择"
                  @change="filterLogisticsData(scope.row)">
                  <el-option v-for=" item in logisticsData " :key="item.logi_id" :label="item.name"
                    :value="item.logi_id" />
                </el-select>
              </template>
            </el-table-column>
            <el-table-column label="物流操作" width="260px">
              <template slot-scope="scope">
                <ul class="wlcz" style="list-style: none; padding-left: 10px; margin-bottom: 0;">
                  <li v-for="( item, index ) in scope.row.delivery_list " :key="index" style="display: flex;">
                    <el-input :disabled="scope.row.state === 3" size="mini" placeholder="请输入物流单号"
                      v-model="item.delivery_no"></el-input>
                  </li>
                </ul>
              </template>
            </el-table-column>
          </template>
        </en-table-layout>
      </div>
    </x-dialog>
    <x-dialog :proxy="remarkDialog">
      <div style="padding:10px">
        <el-input type="textarea" :autosize="{ minRows: 8, maxRows: 10 }" placeholder="请输入不超100字的备注信息" maxlength="100"
          v-model="remarkForm.remark"></el-input>
      </div>
    </x-dialog>
    <x-dialog :proxy="logisticsDialog">
      <!-- <div style="padding:10px"> -->
      <en-table-layout :table-data="sku_list">
        <template slot="table-columns">
          <el-table-column prop="goods_sn" label="商品编号" width="150"></el-table-column>
          <el-table-column prop="goods_name" label="商品名称" min-width="200">
            <template slot-scope="{row}">
              {{ row.goods_name.replaceAll('amp;', '').replaceAll(' ', '&nbsp;') }}
            </template>
          </el-table-column>
          <el-table-column prop="ship_num" label="商品数量" width="100"></el-table-column>
          <el-table-column label="物流公司" width="200">
            <template slot-scope="scope">
              <el-select size="mini" filterable :remote-method="remoteMethod" :disabled="scope.row.state === 3"
                v-model="scope.row.logi_id" @focus="remoteFocus" placeholder="请选择"
                @change="filterLogisticsData(scope.row)">
                <el-option v-for=" item in logisticsData " :key="item.logi_id" :label="item.name"
                  :value="item.logi_id" />
              </el-select>
              <!--                <input type="text" style="display: none" v-model="scope.row.logi_name"/>-->
            </template>
          </el-table-column>
          <el-table-column label="物流单号" width="260px">
            <template slot-scope="scope">
              <ul class="wlcz" style="list-style: none; padding-left: 10px; margin-bottom: 0;">
                <el-input size="mini" placeholder="请输入物流单号" v-model="scope.row.delivery_no"></el-input>
              </ul>
            </template>
          </el-table-column>
        </template>
      </en-table-layout>
      <!-- </div> -->
    </x-dialog>
    <el-dialog :title="funcName === 'importAndShipSellerOrder' ? '批量导入并发货' : '导入历史物流信息'" :visible.sync="batchImport"
      width="85%" class="import-order-dialog" @closed="importData = []">
      <div class="buttonAll">
        <upload-excel-component :on-success="excelSuccess" :cancel-loading='true' />
      </div>
      <el-table :data="importData"
        :header-cell-style="{ textAlign: 'center', backgroundColor: 'rgba(230, 236, 247, 1)' }">
        <el-table-column label="序号" align="center">
          <template slot-scope="scope">{{ scope.$index + 1 }}</template>
        </el-table-column>
        <el-table-column v-for="( item, index ) in templateKey " :prop="item" :key="item" align="center"
          :label="templateHedaer[index]" :width="index==0?135:''"></el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer">
        <el-button @click="batchImport = false">取 消</el-button>
        <el-button type="primary" @click="submitFh">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { Foundation } from "@/../ui-utils";
import * as API_logistics from "@/api/expressCompany";
import * as API_order from "@/api/order";
import { CategoryPicker } from "@/components";
import UploadExcelComponent from "@/components/UploadExcel";
import { $xDialog } from "@/components/x-dialog/dialog.proxy";
import XDialog from "@/components/x-dialog/x-dialog";
import { handleDownload, mergeSkuList, mergesHandleDownload } from "@/utils";
import { mapGetters } from "vuex";
import EnTableLayout from "../../../../ui-components/TableLayout/src/main";
// import * as API_Member from '@/api/member';
import * as API_Goods from '@/api/goods';
import * as API_SHOP from '@/api/shop';
import statusZiti from "@/assets/goods/ziti.png";
import PinyinMatch from 'pinyin-match';
import { getSellerShopExtAuth } from "@/api/account";
export default {
  name: "orderList",
  components: {
    XDialog,
    EnTableLayout,
    [CategoryPicker.name]: CategoryPicker,
    UploadExcelComponent
  },
  computed: {
    ...mapGetters(["shopInfo"]),
    timeDefault () {
      const date = new Date();
      return (
        date.getFullYear() +
        "-" +
        (date.getMonth() + 1) +
        "-" +
        (date.getDate() - 1)
      );
    },
    // 使用箭头函数确保this指向不变
    formattedPrice: () => {
      const TRADE_KINDS_REQUIRING_SHOP_GOODS_PRICE = [1, 5, 6];
      // 使用一个具名函数提高可读性
      return function calculatePrice (row) {
        let price = ''
        // 使用 === 进行比较，避免类型强制转换
        if (this.shopInfo && this.shopInfo.shop_type === 2) {
          if (row.trade_kind === 1) {
            price = row.supplier_order_price;
          } else {
            price = 0;
          }
        } else {
          if (TRADE_KINDS_REQUIRING_SHOP_GOODS_PRICE.includes(row.trade_kind)) {
            price = row.shop_goods_price + row.shop_freight_price;
          } else {
            price = 0;
          }
        }
        return price;
      }
    },
    selectedPrice: () => {
      return function calculatePrice (row) {
        let price = ''
        if (this.shopInfo && this.shopInfo.shop_type === 2) {
          price = row.supplier_order_price
        } else {
          price = row.total_self_owned_orders_price
        }
        return price;
      }
    },
  },
  filters: {
    tradeformFilter (status) {
      const statusMap = {
        1: "普通商品订单",
        2: "礼包订单",
        3: "计次卡订单",
        4: "宅配卡订单"
      };
      return statusMap[status];
    },
  },
  data () {
    return {
      // 商城类型名称的映射表
      mallTypeNames: {
        2: '现金商城',
        3: '套餐卡商城',
        4: '智能套餐卡商城',
        5: '双选商城',
        6: '小程序商城',
        7: '一件代发商城',
      },
      orderSourceMap: {
        0: '平台',
        1: '京东',
        3: '自有',
        4: '蛋糕叔叔',
        5: '企业自有供应商'
        // 新增其他来源时，在这里添加
      },
      merges: [],//表格导出合并
      parentLogin: 1,
      /** 列表loading状态 */
      loading: false,
      importLoading: false,
      importLoading1: false,
      importLoading2: false,
      dialogVisible: false,

      // 列表选中数据
      tempList: [],
      //订单审核选中
      select_box:[],

      ownOrderStatus: [[
        {
          value: '',
          label: '全部'
        },
        {
          value: 'CONFIRM',
          label: '待付款'
        },
        {
          value: 'PAID_OFF',
          label: '待发货'
        },
        {
          value: 'SHIPPED',
          label: '待收货'
        },
        {
          value: 'COMPLETE',
          label: '已完成'
        },
        {
          value: 'CANCEL_IN',
          label: '取消中'
        },
        {
          value: 'CANCELLED',
          label: '已取消'
        },
        {
          value: 'PAID_OFF_HANGUP',
          label: '待发货挂起'
        }
      ], [
        {
          value: '',
          label: '全部'
        },
        {
          value: 'WAIT_PAY',
          label: '待付款'
        },
        {
          value: 'WAIT_SHIP',
          label: '待发货'
        },
        {
          value: 'PICKING',
          label: '拣货中'
        },
        {
          value: 'WAIT_ROG',
          label: '待收货'
        },
        {
          value: 'COMPLETE',
          label: '已完成'
        },
        {
          value: 'CANCEL_IN',
          label: '取消中'
        },
        {
          value: 'CANCELLED',
          label: '已取消'
        }
      ]],

      orderPublishDialog: $xDialog.create({
        title: "订单发货",
        width: "80vw",
        disableConfirm: true,
        disableCancel: true,
        showClose: true
      }),
      logisticsDialog: $xDialog.create({
        title: "物流信息",
        width: "80vw",
        showClose: true,
        displayFooterExtras: true,
        beforeConfirm: this.editLogistics
      }),
      remarkDialog: $xDialog.create({
        title: "备注信息",
        width: "40vw",
        showClose: true,
        displayFooterExtras: true,
        beforeConfirm: this.editRemark
      }),
      // 备注信息
      remarkForm: {
        sn: "",
        platform: "",
        remark: "",
        member_name: ""
      },
      activeName: null,

      /** 列表参数 */
      params: {
        page_no: 1,
        page_size: 20,
        order_status: "",
        activity_group_by_filter: 'INCLUDE',//不展示拼团订单
        activity_group_by_status:'GROUPED',
        logistics_status: undefined,
        mall_type: this.$route.name === 'orderList' ? 2 : (this.$route.name === 'comboOrderList' ? 3 : ''),
        ...this.$route.query
      },
      mallOptions: [{
        value: '',
        label: '全部'
      }, {
        value: '2',
        label: '现金商城'
      }, {
        value: '3',
        label: '套餐卡商城'
      }],
      /** 列表分页数据 */
      pageData: {},
      //来源应用数组
      mallTypeList: [
        { name: '全部', value: '' },
        { name: '现金商城', value: '2' },
        { name: '套餐卡商城', value: '3' },
        { name: '智能套餐卡商城', value: '4' },
        { name: '小程序商城', value: '6' },
      ],
      comParams: {
        cgrade: 2,
        page_no: 1,
        page_size: 20
      },
      /** 高级搜索数据 */
      advancedForm: {
        shop_member_unit_id: "",
        shop_member_unit_id_two: "",
        order_time_range: []
      },
      batchImport: false,
      importData: [],
      isError: false,
      templateHedaer: [
        "商品名称以及规格",
        "商品编号",
        "数量",
        "详细地址",
        "姓名",
        "电话",
        "订单号",
        "订单状态",
        "物流公司",
        //"物流状态",
        "物流单号",
        "下单账号"
      ],
      templateKey: [
        "name",
        "sku_sn",
        "num",
        "ship_addr",
        "ship_name",
        "ship_mobile",
        "sn",
        "order_status_text",
        "logi_name",
        //"ship_status_text",
        "ship_no",
        "member_name"
      ],
      funcName: "",
      /** 表格最大高度 */
      tableMaxHeight: "",
      search_type: "order_sn",
      obj: {},
      /*订单商品数据*/
      sku_list: [],
      selectList: [],
      ship_no: "",
      /** 物流信息 */
      logisticsData: [],
      logistics_list: [],
      formInline: {
        logi_id: ""
      },
      orderDetail: {},
      hasOwnGoods: false,
      addOrderLoading: false,
      pollTime: null,
      progress: '0%',
      tableData: [],
      tableDataTwo: [],
      showExamine: false,
    };
  },
  async created () {

    const mallTypeList = await API_order.getShopByOrder();
    const doubledata = await API_order.getShopExtAuth();
    if (mallTypeList) {//有订单
      this.mallTypeList.push({ name: "双选商城", value: "5" })
    } else {//无订单判断大平台开关
      doubledata.double_choice_mall_open === "OPEN" ? this.mallTypeList.push({ name: "双选商城", value: "5" }) : null;
    }
    doubledata.drop_shipping_mall_open === "OPEN" ? this.mallTypeList.push({ name: "一件代发商城", value: "7" }) : null;
  },
  activated () {
    this.GET_OrderList(this.activeName);
    this.$store.commit("DEL_VISITED_VIEWS", this.$route);
  },
  mounted () {
    this.$refs.enTableLayout.$refs.toolbar_btn.style.display = 'none'
    this.pollImportResult('init')
    if (sessionStorage.getItem('orderListActiveName') === '2') {
      this.activeName = sessionStorage.getItem('orderListActiveName')
    } else {
      this.activeName = '1'
    }
    delete this.params.market_enable;
    let { type } = this.$route.query;
    this.params = {
      ...this.params,
      ...this.$route.query
    };

    API_Goods.getLoginType().then((res) => {
      this.parentLogin = res.parentLogin;
      this.parentId = res.parentId
      API_SHOP.getShopData().then(res => {
        // 站点后台修改货币单位后，导出的Excel表格中内容项需同步变更,因此在初次进入订单页面重新获取相关信息
        // 如果后续报这个没有实时变更,可考虑将此处代码移动到导出订单的函数中
        this.realTimeShopInfo = res
        this.isSpecificEnterprise = this.isSpecificEnterpriseAndShop()
      });
    });
    if (this.shopInfo.shop_type === 1) {//现金商城
      this.params["shop_type"] = 1;
    } else if (this.shopInfo.shop_type === 3) {//企业端
      this.params["shop_type"] = 2;
    }

    const arr = [];

    switch (type) {
      case "1":
        this.params.order_status = "PAID_OFF";
        break;
      case "3":
        this.params.order_status = "";
        arr.push(this.timeDefault);
        arr.push(this.timeDefault);
        // 初始化搜索，默认为前一天
        this.advancedForm.order_time_range = arr;
        let now_date = new Date(); //获取Date对象
        now_date.setHours(0); //设置小时
        now_date.setMinutes(0); //设置分钟
        now_date.setSeconds(0); //设置秒
        now_date.setMilliseconds(0); //设置毫妙
        let timestamp = now_date.getTime() / 1000; //获取毫秒时间戳
        this.params = {
          ...this.params,
          start_time: timestamp - 86400,
          end_time: timestamp - 1
        };
        break;
      case "4":
        this.params.order_status = "COMPLETE";
        arr.push(this.timeDefault);
        arr.push(this.timeDefault);
        // 初始化搜索，默认为前一天
        this.advancedForm.order_time_range = arr;
        let now_date1 = new Date(); //获取Date对象
        now_date1.setHours(0); //设置小时
        now_date1.setMinutes(0); //设置分钟
        now_date1.setSeconds(0); //设置秒
        now_date1.setMilliseconds(0); //设置毫妙
        let timestamp1 = now_date1.getTime() / 1000; //获取毫秒时间戳
        this.params = {
          ...this.params,
          start_time: timestamp1 - 86400,
          end_time: timestamp1 - 1
        };
        break;
      default:
        break;
    }

    if (this.shopInfo.shop_type === 3) {
      API_order.getEnterpriseISZiYou().then(res => {
        this.hasOwnGoods = res === 1
      })
    }

    this.GET_OrderList(this.activeName);
    this.getLogisticsCompanies();
    //查询挂起订单恢复模式
    this.getExamine()
  },
  beforeRouteUpdate (to, from, next) {
    delete this.params.market_enable;
    this.params = {
      ...this.params,
      ...this.$route.query
    };
    this.GET_OrderList();
    next();
  },
  destroyed () {
    sessionStorage.removeItem('orderListActiveName')
  },
  methods: {
    async getExamine () {
      const isCardSale = await getSellerShopExtAuth(this.shopInfo.shop_id)
    // 挂起订单恢复模式,目前两种模式: AUTO: 自动恢复,MANUAL: 手动恢复
      this.showExamine = isCardSale.hang_order_recovery_mode == 'MANUAL';//是否等于手动恢复
    },
    /**订单审核 */
    async orderExamine () {
      if (this.select_box.length === 0) return this.$message.error("请先勾选数据");
      const isTempList = this.select_box.every(item => [1].includes(item.hangup_status))
      if (!isTempList) return this.$message.error('存在非挂起状态的订单数据，请重新选择数据')
      await this.getExamine();
      if (this.showExamine == false) return this.$message.error('当前订单审核功能已发生变更，请刷新页面！')
      this.$confirm('确定要将当前勾选的订单进行恢复吗？', '提示', { type: 'warning' }).then(() => {
        // 若第一个订单就无法恢复，则后续不再继续判断，给出吐司提示信息——商城账户金额不足，无法进行订单恢复！
        // 操作完成！本次共操作x条数据，其中成功y条，失败z条
        let order_sn_s = this.activeName === '1' ? this.select_box.map(item => item.trade_sn) : this.select_box.map(item => item.sn)
        API_order.recoveryTrade(order_sn_s, 1).then(res => {
          this.GET_OrderList();
          this.$message.success(`本次共操作${res.all_count}条数据，其中成功${res.success_count}条，失败${res.fail_count}条`);
          this.$refs.enTableLayout.clearSelected();
        })
      })
    },
    // 使用一个方法来格式化地址，通过传入不同的参数来区分收货地址和发货地址
    formatAddress (row, type) {
      // 定义一个数组来存储对应类型的地址字段
      const address = type == 'consignee' ? 'address' : 'addr';
      const addressFields = [`${type}_province`, `${type}_city`, `${type}_county`, `${type}_town`, `${type}_${address}`];
      // 使用数组的map方法将字段名映射为字段值，然后过滤掉null或undefined的值
      const addressParts = addressFields.map(field => row[field] || '').filter(Boolean);
      // 使用join方法将地址部分连接起来，用空字符串分隔
      return addressParts.join('');
    },
    getRowTagImage (row) {
      if (row.delivery_method == 2 || row.delivery_method == 3) return statusZiti
    },
    //首字母拼音检索物流公司
    remoteMethod (query) {
      if (query !== '') {
        let result = [];
        const this_PinyinMatch = PinyinMatch;
        this.logistics_list.forEach(item => {
          let match = this_PinyinMatch.match(item.name, query);
          match ? result.push(item) : null;
        })
        this.logisticsData = result;
      } else {
        this.logisticsData = this.logistics_list;
      }
    },
    remoteFocus () {
      if (this.logisticsData == '') {
        this.logisticsData = this.logistics_list;
      }
    },
    orderListSelectionChange (list) {
      this.tempList = list
    },
    /**全选 */
    orderListSelectionAll (arr) {
      //全选先把所有id拿到并去重
      const map = new Map();
      const newArr = arr.filter(v => !map.has(v.trade_sn) && map.set(v.trade_sn, v)) 
      this.select_box = newArr;
    },
    /**单选 */
    selectionCheckOne (val, row) {
      //isSelect true为选中 false 为取消
      let isSelect = val.length && val.indexOf(row) > -1
      //findIndex返回-1则不存在，>0则存在
      let n = this.select_box.findIndex(v => {
        return v.trade_sn == row.trade_sn
      })
      if (isSelect) {
        if (n < 0) this.select_box.push(row)
      } else {
        if (n > -1) this.select_box.splice(n, 1)
      }
    },
    /**单元格合并方法 */
    orderSpanMethod ({ row, column, rowIndex, columnIndex }) {
      if (columnIndex >= 3 && columnIndex <= 9 || column.label == '供货方') {
        return {
          rowspan: 1,
          colspan: 1
        };
      } else {
        return row.span;
      }
    },
    /** 获取物流公司信息列表 */
    getLogisticsCompanies () {
      API_logistics.getExpressCompanyList({}).then(res => {
        this.logisticsData = res;
        this.logistics_list = res;
      });
    },
    deliverDialog (sn, ship_no) {
      this.sku_list = [];
      this.sn = sn;
      this.ship_no = ship_no;

      this.orderPublishDialog.display();
      API_order.getOrderItemsList(sn).then(response => {
        if (response && response.length) {
          let sku_list = [...response];
          sku_list.forEach(item => {
            if ((item.service_status != 'COMPLETED') &&
              (!item.delivery_list ||
                !item.delivery_list.length ||
                !item.delivery_list[0].delivery_no)
            ) {
              item.delivery_list = [{}];
              this.sku_list.push(item);
            }
          });
          this.rawData = JSON.stringify(this.sku_list)
          this.$nextTick(()=>{
            this.$refs.multipleTable.$refs.table.toggleAllSelection()
          })
        }
      });

      if (this.shopInfo.shop_type === 2) {
        API_order.getSellerOrderDetail(sn).then(response => {
          // 订单信息
          if (
            response.order_status === "CANCELLED" ||
            response.service_status === "APPLY"
          ) {
            this.$message.error("该订单已取消，不需要发货");
          }
          this.orderDetail = response;
        });
      } else {
        API_order.getOrderDetail(sn).then(response => {
          // 订单信息
          if (
            response.order_status === "CANCELLED" ||
            response.service_status === "APPLY"
          ) {
            this.$message.error("该订单已取消，不需要发货");
          }
          this.orderDetail = response;
        });
      }
      // setTimeout(() => {
      //   this.$refs.multipleTable.$refs.table.toggleAllSelection()
      // }, 500)
      
    },
    openLogisticsDialog (sn) {
      this.sku_list = [];
      this.sn = sn;
      this.logisticsDialog.display();
      API_order.getLogisticsList(this.sn).then(res => {
        this.sku_list = res
      });
    },
    editLogistics () {
      let delivery_dolist = [];
      let params = JSON.stringify(this.sku_list[0].delivery_list)
      API_order.editLogistics(this.sku_list).then(res => {
        this.$message.success("保存成功");
        this.logisticsDialog.dismiss()
        this.GET_OrderList()
      });
    },
    openRemarkDialog (row) {
      this.remarkDialog.display();
      this.remarkForm.sn = row.sn; //订单编号
      this.remarkForm.platform = 2; //平台编号（0：供应商 1：大平台 2：企业端）
      this.remarkForm.remark = //订单备注
        row.order_remark === null
          ? row.order_remark
          : row.order_remark.replaceAll("/n", "\n");
      this.remarkForm.member_name = row.member_name;
    },
    editRemark () {
      if (this.remarkForm.remark === "" || this.remarkForm.remark === null) {
        return this.$message.error("备注信息不能为空");
      }
      this.remarkForm.remark = this.remarkForm.remark.replace(/\n/g, "/n");
      API_order.editOrderRemark(this.remarkForm).then(res => {
        this.$message.success("保存成功");
        this.GET_OrderList("2");
      });
    },
    // closeRemarkDialog() {
    //     this.remarkDialog = false;
    //     this.remarkTextarea = "";
    // },
    handleSelectionChange (list) {
      this.selectList = list;
    },

    checkboxT (row, index) {
      return row.state !== 3;
    },

    submitFhing () {
      for (let i = 0; i < this.selectList.length; i++) {
        let index = i;
        let item = this.selectList[i];
        if (!item.logi_id) {
          this.$message.error("请选择物流公司");
          return false;
        }
        if (item.delivery_list) {
          for (let j = 0; j < item.delivery_list.length; j++) {
            let item1 = item.delivery_list[j];
            if (!item1.delivery_no) {
              this.$message.error("请输入物流单号");
              return false;
            }
          }
        }
      }
      this.$confirm("确认发货?", "提示", { type: "warning" }).then(() => {
        let fhInfo = {
          order_sn: this.sn,
          delivery_dolist: []
        };
        let sku_list = [];
        JSON.parse(this.rawData).forEach(elem => {
          this.selectList.forEach((item, index) => {
            item.delivery_list.forEach((item1, index1) => {
              if (JSON.stringify(elem.delivery_list) === "[{}]") {
                if (elem.goods_id === item.goods_id) {
                  fhInfo.delivery_dolist.push({
                    logi_id: item.logi_id,
                    logi_name: item.logi_name,
                    order_sn: this.sn,
                    sku_id: item.product_id,
                    goods_id: item.goods_id,
                    goods_name: item.name,
                    goods_sn: item.sku_sn,
                    ship_num: item.num,
                    delivery_no: item1.delivery_no
                  });
                }
              } else {
                sku_list.push({
                  logi_id: item.logi_id,
                  logi_name: item.logi_name,
                  order_sn: this.sn,
                  sku_id: item.product_id,
                  goods_id: item.goods_id,
                  goods_name: item.name,
                  goods_sn: item.sku_sn,
                  id: item1.id,
                  ship_num: item.num,
                  delivery_no: item1.delivery_no
                })
              }
            });
          });
        })
        if (sku_list.length !== 0 && fhInfo.delivery_dolist.length !== 0) {
          API_order.deliveryNew(fhInfo).then(() => {
            API_order.editLogistics(sku_list).then(res => {
              this.$message.success("保存成功");
              this.GET_OrderList("2");
              this.orderPublishDialog.dismiss()
            });
          });
        } else if (fhInfo.delivery_dolist.length === 0) {
          API_order.editLogistics(sku_list).then(res => {
            this.$message.success("修改成功");
            this.GET_OrderList("2");
            this.orderPublishDialog.dismiss()
          });
        } else {
          API_order.deliveryNew(fhInfo).then(res => {
            this.$message.success("发货成功");
            this.GET_OrderList("2");
            this.orderPublishDialog.dismiss()
          });
        }
      });
    },

    filterLogisticsData (row) {
      this.logisticsData.forEach(res => {
        if (res.logi_id === row.logi_id) {
          row.logi_name = res.name;
        }
      });
    },

    // 标签切换
    handleClick (tab) {
      this.search_type = "order_sn";
      this.tableData = []
      this.tableDataTwo = []
      this.advancedForm = {
        shop_member_unit_id: "",
        shop_member_unit_id_two: "",
        order_time_range: []
      }
      this.params = {
        page_no: 1,
        page_size: 20,
        order_status: "",
        activity_group_by_filter: 'INCLUDE',//不展示拼团订单
        activity_group_by_status:'GROUPED',
        mall_type: this.$route.name === 'orderList' ? 2 : (this.$route.name === 'comboOrderList' ? 3 : ''),
        ...this.$route.query
      };
      if (this.activeName === "1") {
        this.params["shop_type"] = 2;
      }

      this.GET_OrderList(tab.name);
    },

    /** 分页大小发生改变 */
    handlePageSizeChange (size) {
      this.params.page_no = 1;
      this.params.page_size = size;
      this.GET_OrderList(this.activeName);
    },

    /** 分页页数发生改变 */
    handlePageCurrentChange (page) {
      this.params.page_no = page;
      this.GET_OrderList(this.activeName);
    },

    /** 高级搜索事件触发 */
    advancedSearchEvent () {
      this.select_box = [];
      this.$refs.enTableLayout.clearSelected();
      // 初始化搜索条件
      delete this.params.order_sn;
      delete this.params.buyer_name;
      delete this.params.goods_name;
      delete this.params.ship_no;
      delete this.params.ship_mobile;
      delete this.params.card_code;
      delete this.params.card_number;
      delete this.params.shop_name;

      this.params.booking_shipment = 0;

      this.obj = {};
      if (this.search_type) {
        this.obj[this.search_type] = this.advancedForm.keywords;
      }


      this.params = {
        ...this.params,
        ...this.advancedForm,
        ...this.obj
      };

      // delete this.params.order_status;
      delete this.params.start_time;
      delete this.params.end_time;
      delete this.params.deliverStartTime;
      delete this.params.deliverEndTime;
      if (
        this.advancedForm.order_time_range &&
        this.advancedForm.order_time_range.length
      ) {
        if (typeof this.advancedForm.order_time_range[0] === "string") {
          this.params.start_time =
            new Date(this.advancedForm.order_time_range[0]).getTime() / 1000;
          this.params.end_time =
            new Date(this.advancedForm.order_time_range[1]).getTime() / 1000 +
            86400;
        } else {
          this.params.start_time =
            this.advancedForm.order_time_range[0].getTime() / 1000;
          this.params.end_time =
            this.advancedForm.order_time_range[1].getTime() / 1000 + 86400;
        }
      }
      delete this.params.keywords;
      delete this.params.order_time_range;
      this.params.page_no = 1;
      if (this.activeName === "1") {
        this.GET_OrderList("1");
      } else {
        if (this.params.end_time) {
          this.params.end_time = this.params.end_time - 86400;
        }
        this.GET_OrderList("2");
      }
    },

    /** 查看、操作订单 */
    handleOperateOrder (item) {
      this.$store.dispatch("addVisitedViews", this.$route);
      let route = this.$route.path.split("/")[2];
      if (this.activeName === "1") {
        this.$router.push({
          path: `/order/${route}/detail/${item.trade_sn}?type=${this.activeName}`
        });
      } else {
        this.$router.push({
          path: `/order/${route}/detail/${item.sn}?type=${this.activeName}`
        });
      }
    },

    /** 取消订单 */
    cancelTradeHandle (row) {
      if (row.delivery && row.delivery.deliveryNo) {
        this.$message.error("存在已发货商品，无法取消订单");
        this.GET_OrderList();
        return
      }
      this.$confirm('此操作将取消订单, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        API_order.cancelTrade({ trade_sn: row.trade_sn }).then(res => {
          if (!row.isCancelOrder) row.isCancelOrder = 1
          this.$message({
            type: 'success',
            message: '订单取消成功'
          });
          this.GET_OrderList();
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消操作'
        });
      });
    },

    excelSuccess ({ results }) {
      this.isError = false;
      let resultsList = [];
      results.forEach(list => {
        this.templateKey.forEach((item, index) => {
          list = JSON.parse(
            JSON.stringify(list).replace(this.templateHedaer[index], item)
          );
        });
        if (list.order_sn && !list["ship_num"]) {
          list.errorText = "发货数量必填";
          this.isError = true;
        } else if (Number(list["ship_num"]) > Number(list["num"])) {
          list.errorText = "发货数量大于订单数量";
          this.isError = true;
        }
        if (list.order_sn && !list["logi_name"]) {
          list.errorText = "物流公司必填";
          this.isError = true;
        }
        if (list.order_sn && !list["delivery_no"]) {
          list.errorText = "物流单号必填";
          this.isError = true;
        }
        resultsList.push(list);
      });
      this.importData = resultsList;
    },

    submitFh () {
      this.progress = '0%'
      if (!this.importData.length) {
        this.$message.error("请导入数据");
      } else if (this.isError) {
        this.$message.error("请按校验标识检查数据重新导入");
      } else {
        let data = [];
        this.importData.forEach(item => {
          data.push({
            order_sn: item.sn,
            goods_name: item.name,
            goods_sn: item.sku_sn,
            ship_num: item.num,
            logi_name: item.logi_name,
            delivery_no: item.ship_no
          });
        });
        data = data.filter(res => {
          return res.order_sn;
        });
        API_order.importOrderRequest(Math.ceil(data.length / 100))
        for (let i = 1; i <= Math.ceil(data.length / 100); i++) {
          setTimeout(() => {  // 为解决后端并发问题添加延时器
            // 拆解数据，分批导入
            let cpData = JSON.parse(JSON.stringify(data))
            let start = (i - 1) * 100
            let end = i * 100 - 1
            let handleData = []
            if (i === Math.ceil(data.length / 100)) {
              end = data.length - 1
              handleData = cpData.splice(start, data.length - start)
            } else handleData = cpData.splice(start, 100)
            // 开始导入数据
            API_order[this.funcName]({ delivery_dolist: handleData }).then(res => {
              const { no, errorExport } = res;
              if (no !== 0) {
                const { tHeaders, filterVals } = getExportMetadata(2);
                handleDownload(
                  errorExport,
                  tHeaders,
                  filterVals,
                  "失败订单列表"
                );
              }
            });
            this.addOrderLoading = true
            this.batchImport = false;
          }, (i - 1) * 1500)
        }
        this.pollImportResult()
      }
    },

    pollImportResult (type) {
      if (this.pollTime) clearInterval(this.pollTime)
      this.pollTime = setInterval(() => {
        API_order.pollImportResult().then(res => {
          if (res.surplus === 0) {
            clearInterval(this.pollTime)
            this.addOrderLoading = false
            this.GET_OrderList();
          } else {
            this.progress = Math.ceil(((res.total - res.surplus) / res.total) * 100) + '%'
            this.addOrderLoading = true
          }
        })
      }, 3000)
      if (type === 'init') {
        API_order.pollImportResult().then(res => {
          if (res.surplus === 0) {
            clearInterval(this.pollTime)
            this.addOrderLoading = false
          } else {
            this.progress = Math.ceil(((res.total - res.surplus) / res.total) * 100) + '%'
            this.addOrderLoading = true
          }
        })
      }
    },
    /**
     * 企业跳转站点、单独登录站点时导出的订单列表上同步增加用户支付金额的字段
     * 判断当前是否是特定企业,目前只有北京因特帕克万通商贸有限公司
     * - 如果后面此处企业变多,可以考虑让后端提供接口来处理
     */
    isSpecificEnterpriseAndShop () {
      let shopType = this.realTimeShopInfo.shop_type;
      if (shopType !== 6 && shopType !== 1) {
        return false
      }
      // let enterpriseId = 386; // 研发环境测试
      let enterpriseId = 1677
      // 1677 北京因特帕克万通商贸有限公司
      if (this.parentLogin === 1) {
        return enterpriseId === this.parentId
      }
      return enterpriseId === this.realTimeShopInfo.parent_id
    },
    /**
     * 格式化订单的个人支付金额
     * @param {*} trade 订单数据
     */
    formatUserPayAmountByTrade (trade) {
      // 只有订单真实支付才会展示该字段的内容
      const tradeStatusList = ["PAID_OFF", "SHIPPED", "COMPLETE"]
      if (!tradeStatusList.includes(trade.trade_status)) {
        return ''
      }
      // discount_card_coupon_price  优惠券优惠价格
      // discount_card_voucher_price  提货券支付价格
      // weixin_pay_money 微信支付价格
      // account_pay_money 账户余额支付价格
      return `提货券支付金额：${this.formatMonetaryUnitWithPrice(trade.discount_card_voucher_price, this.realTimeShopInfo.monetary_unit)} +`
        + ` 账户支付金额：${this.formatMonetaryUnitWithPrice(trade.account_pay_money, this.realTimeShopInfo.monetary_unit)}+`
        + ` 微信支付金额：¥${trade.weixin_pay_money}`
    },
    /**
     * 根据站点配置格式化金额数据
     * @param {*} price 金额值
     * @param {*} unit 单位,只有¥输出在金额前面,其余的一律输出在后面
     */
    formatMonetaryUnitWithPrice (price, unit) {
      if (!unit) {
        unit = '¥';
      }
      if (unit === '¥') {
        return '¥' + price;
      }
      return price + unit;
    },
    //站点订单列表导出方法
    submitImport () {
      this.merges = []
      this.importLoading = true;
      let tHeaders = [];
      let filterVals = [];
      if (this.parentLogin === 1) {
        tHeaders = [
          "订单编号",
          "订单总额",
          "商品名称",
          "商品单价",
          "分销价",
          "销售价",
          "会员价",
          "商品数量",
          "售后状态",
          "物流状态",
          "物流单号",
          "供货方",
          "订单状态",
          "运费",
          "卡号及公司名称",
          "下单时间",
          "下单账号",
          "下单人所属分组",
          "收件人",
          "收件电话",
          "收货地址",
          "下单备注信息"
        ];
        filterVals = [
          "trade_sn",
          "order_amount",
          "goods_name",
          "shop_price",
          "enterprise_price",
          "shop_price",
          "original_price",
          "goods_num",
          "service_status",
          "state",
          "deliveryNo",
          "order_source",
          "trade_status_text",
          "shop_freight_price",//运费
          "card_no_company",
          "create_time",
          "member_name",
          "group_name",//所属分组
          "consignee_name",
          "consignee_mobile",
          "address",
          "remark"
        ];
      } else {
        tHeaders = [
          "订单编号",
          "订单总额",
          "商品名称",
          "商品单价",
          "分销价",
          "销售价",
          "商品数量",
          "售后状态",
          "物流状态",
          "物流单号",
          "供货方",
          "订单状态",
          "运费",
          "卡号及公司名称",
          "下单时间",
          "下单账号",
          "下单人所属分组",
          "收件人",
          "收件电话",
          "收货地址",
          "下单备注信息"
        ];
        filterVals = [
          "trade_sn",
          "order_amount",
          "goods_name",
          "shop_price",
          "enterprise_price",
          "shop_price",
          "goods_num",
          "service_status",
          "state",
          "deliveryNo",
          "order_source",
          "trade_status_text",
          "shop_freight_price",//运费
          "card_no_company",
          "create_time",
          "member_name",
          "group_name",//所属分组
          "consignee_name",
          "consignee_mobile",
          "address",
          "remark"
        ];
      }
      if (this.shopInfo.shop_id == 1167 && this.parentLogin == 0) {
        tHeaders = tHeaders.filter(item => item != '供货方')
        filterVals = filterVals.filter(item => item != 'order_source')
      }
      // 针对[因特帕克]企业,新增用户支付金额业务
      // - 该单元格内容统一显示提货券支付金额 + 账户支付金额+ 微信支付金额 ,提货券支付金额：¥0.00 + 账户支付金额：¥271.13 + 微信支付金额：¥22.31
      // - 如货币单位修改成积分后。需将提货券支付金额、账户支付金额显示为 xx积分
      if (this.isSpecificEnterprise) {
        // 新增一列
        let index = tHeaders.findIndex(h => h === '订单状态')
        tHeaders.splice(index + 1, 0, '用户支付金额')
        filterVals.splice(index + 1, 0, 'user_pay_amount')
      }
      let params = { ...this.params };

      params.page_no = 0;
      params.page_size = 0;
      API_order.getOrderList(params).then(res => {
        let data = mergeSkuList(res.data, 1);
        data.forEach(item => {
          item.goods_name = item.goods_name.replaceAll('amp;', '').replaceAll(' ', '&nbsp;')
          item.service_status = this.MixinGetOrderStatus(item.service_status);
          //订单总额
          item.order_amount = [1, 5, 6].includes(item.trade_kind) ? item.shop_goods_price + item.shop_freight_price : 0;
          item.state = item.logistics_status_text ? item.logistics_status_text :
            item.state === 0
              ? "未发货"
              : item.state === 2
                ? "已发货"
                : "已收货";
          item.user_pay_amount = this.formatUserPayAmountByTrade(item)
          item.order_source = this.orderSourceMap[item.order_source];
          item.create_time = Foundation.unixToDate(item.create_time);
          item.deliveryNo = item.delivery && item.delivery.deliveryNo;
          item["address"] =
            item.consignee_province +
            item.consignee_city +
            item.consignee_county +
            item.consignee_town +
            item.consignee_address;
          item.platform_price = item.order_source === '自有' ? '---' : item.platform_price;
          item.card_no_company = item.card_no_company ? this.card_no_company_filter(item.card_no_company) : ''



        });
        //需要合并的单元格
        data.forEach((m, index) => {
          let uum = 0;
          let columnCount = 22;
          this.parentLogin === 1 ? uum = 0 : uum = 1 //是否显示会员价
          if (this.isSpecificEnterprise) {
            columnCount += 1;
          }
          if (m.span.rowspan > 1) {
            for (let i = 0; i < columnCount - uum; i++) {
              if (i < 2 || i > 11 - uum) {
                this.merges.push(
                  { s: { r: index + 1, c: i }, e: { r: index + m.span.rowspan, c: i } }
                )
              }
            }
          }
        })
        mergesHandleDownload(data, tHeaders, filterVals, "订单列表", true, this.merges);
        this.importLoading = false;
      });
    },
    /**格式化卡号公司名称 */
    card_no_company_filter (data) {
      let format = '';
      data.forEach((item, index) => {
        if (index == data.length - 1) format += `${item.card_code}-${item.company}`
        else format += `${item.card_code}-${item.company}\n`
      })
      return format
    },
    GET_OrderList (tab = this.activeName) {
      this.loading = true;
      console.log(tab, 'tab')
      // 企业平台商品，站点
      if (tab === "1") {//企业端
        delete this.params.keywords;
        delete this.params.shop_member_unit_id_two;
        delete this.params.shop_member_unit_id;
        delete this.params.booking_shipment;
        delete this.params.type;
        API_order.getOrderList(this.params).then(res => {
          if (this.activeName === '1') this.loading = false
          this.pageData = res;
          this.tableData = mergeSkuList(res.data, 1);
        });
      } else {//现金商城站点端
        let params = JSON.parse(JSON.stringify(this.params))
        if (params.order_status === 'PICKING') {
          params.logistics_status = 1
          delete params.order_status;
        }
        // 企业自由商品
        API_order.getSellerOrderList(params).then(res => {
          if (this.activeName === '2') this.loading = false
          this.pageData = res;
          res.data.map((item) => {
            //获取自有订单商品总额
            item.total_self_owned_orders_price = 0;
            item.sku_list.map((row) => {
              item.total_self_owned_orders_price += row.enterprise_purchase_price * row.num;
            })
          })
          this.tableDataTwo = mergeSkuList(res.data, 2);
        });
      }
    },
    // 取消拣货
    cancelPicking () {
      if (this.tempList.length === 0)
        return this.$message.error("请先勾选要取消拣货的订单");
      this.$confirm('确定要将当前勾选的订单取消拣货吗？', '提示', { type: 'warning' }).then(() => {
        this.importLoading2 = true
        let order_sn_s = this.tempList.map(item => item.sn).join(',')
        API_order.cancelPicking({ order_sn_s }).then(res => {
          this.GET_OrderList();
          this.importLoading2 = false
          return this.$message.success(res);
        })
      })
    },
    filterlogState (row) {//物流状态
      let state = '';
      if (row.delivery_method && row.delivery_method !== 1) {
        state = '未发货'
      } else if (row.logistics_status_text) {
        state = row.logistics_status_text
      } else {
        row.state == 0 ? state = '未发货' : (row.state == 2 ? state = '已发货' : state = '已收货')
      }

      return state
    },
    // 自有订单导出
    ziyousubmitImport (type) {
      const filterVals = [
        "name",
        "supplier_purchase_price",
        "product_id",
        "num",
        "ship_addr",
        "ship_name",
        "ship_mobile",
        "order_sn",
        "order_status_text",
        "logi_name",
        //'ship_status_text',
        "ship_no",
        "member_name",
        "remark",
        "order_remark"
      ];
      const tHeaders = [
        "商品名称以及规格",
        "商品单价",
        "商品编号",
        "数量",
        "详细地址",
        "姓名",
        "电话",
        "订单号",
        "订单状态",
        "物流公司",
        //'物流状态',
        "物流单号",
        "下单账号",
        "下单备注信息",
        "备注信息"
      ];


      let obj = {};
      if (this.search_type) {
        obj[this.search_type] = this.advancedForm.keywords;
      }
      let params = {
        ...this.params,
        ...obj,
      };
      if (
        this.advancedForm.order_time_range &&
        this.advancedForm.order_time_range.length
      ) {
        if (typeof this.advancedForm.order_time_range[0] === "string") {
          params.start_time =
            new Date(this.advancedForm.order_time_range[0]).getTime() / 1000;
          params.end_time =
            new Date(this.advancedForm.order_time_range[1]).getTime() / 1000 +
            86400;
        } else {
          params.start_time =
            this.advancedForm.order_time_range[0].getTime() / 1000;
          params.end_time =
            this.advancedForm.order_time_range[1].getTime() / 1000 + 86400;
        }
      }
      params.page_no = 0;
      params.page_size = 0;
      if (type === 0) {
        if (this.tempList.length === 0)
          return this.$message.error("请先勾选要导出的订单");
        let snArr = [];
        this.tempList.forEach(item => {
          snArr.push(item.sn);
        });
        params.order_sn_s = snArr.toString();
        this.importLoading = true;
        if (params.order_status === 'PICKING') {
          params.logistics_status = 1
          delete params.order_status;
        }
        //如果只是导出选中只传递订单编号 去掉其他搜索条件
        params = {
          page_no: 0,
          page_size: 0,
          order_sn_s: snArr.toString(),
        }
      } else {
        this.importLoading1 = true;
        if (params.order_status === 'PICKING') {
          params.logistics_status = 1
          delete params.order_status;
        }
      }
      // params.order_status = ""
      API_order.getSellerOrderList(params)
        .then(res => {
          let data = res.data.map(item => {
            const update = item.remark_update_date ? Foundation.unixToDate(item.remark_update_date) : ''
            const order_remark = item.order_remark ? item.order_remark.replaceAll("/n", "\n") : ''
            return {
              name: item.name.replaceAll('amp;', '').replaceAll(' ', '&nbsp;'),
              sku_sn: item.sku_sn,
              num: item.num,
              product_id: item.product_id,
              ship_addr:
                item.ship_province +
                item.ship_city +
                item.ship_county +
                (item.ship_town || '') +
                item.ship_addr,
              ship_name: item.ship_name,
              ship_mobile: item.ship_mobile,
              order_sn: item.order_sn,
              //订单总额
              order_amount: item.trade_kind == 1 ? (item.shop_goods_price + item.shop_freight_price) : 0,
              // order_status_text: item.order_status_text,
              logi_name: item.logi_name,
              ship_no: item.ship_no,
              member_name: (item.mobile || '') + (item.mobile && item.login_account ? '\n' : '') + (item.login_account ? item.login_account : ''),
              remark: item.remark,
              order_remark: order_remark ? update + ':' + order_remark : '',
              order_status_text: item.jd_cancel_status == 1 ? "取消中" : item.order_status_text,
              supplier_purchase_price: item.supplier_purchase_price
            };
          });
          handleDownload(data, tHeaders, filterVals, "订单列表");
          this.GET_OrderList(2);
          this.importLoading = false;
          this.importLoading1 = false;
        })
        .catch(res => {
          this.importLoading = false;
          this.importLoading1 = false;
        });
    }
  }
};

// 暂时废弃
function getExcelMetadata (shop_type, type) {
  let orderFunc, filterVals, tHeaders;

  if (shop_type === 2) {
    //供应商
    orderFunc = "getSellerOrderList";
    filterVals = [
      // "pay_order_no",
      // "shop_member_type",
      "order_sn",
      "create_time",
      "payment_time",
      "name",
      "jifenType",
      "source",
      "price",
      "point",
      "num",
      "spec_name",
      "sku_sn",
      "spze",
      "spzjf",
      "ship_name",
      "ship_mobile",
      "shipAddr",
      "remark",
      "ship_num",
      "logi_name",
      "ship_no",
      "order_status_text",
      "pay_member_name",
      "pay_status_text",
      "ship_status_text",
      "service_status",
      "payment_type_text",
      "payment_method_name",
      "client_type"
    ];
    tHeaders = [
      // "交易流水号",
      // "下单人身份类型",
      "订单编号",
      "下单时间",
      "支付时间",
      "商品名称",
      "商品类型",
      "商品结算类型",
      "价格",
      "积分",
      "数量",
      "规格",
      "商品编码",
      "商品总额",
      "商品总积分",
      "收货人",
      "收货人联系方式",
      "收货地址",
      "订单备注",
      "发货数量",
      "物流公司",
      "物流单号",
      "订单状态",
      "付款人",
      "付款状态",
      "发货状态",
      "售后状态",
      "支付方式",
      "支付渠道",
      "订单来源"
    ];
  } else if (shop_type !== null) {
    orderFunc = "getOrderList";
    filterVals = [
      // "pay_order_no",
      // "shop_member_type",
      "sn",
      "create_time",
      "payment_time",
      "goodnweeess",
      "jifenType",
      "source",
      "revise_exchange_money",
      "revise_exchange_point",
      "num",
      "spec_name",
      "sku_sn",
      "spze",
      "spzjf",
      "shop_order_price",
      "shop_consume_point",
      "shop_deduct_money",
      "discount_price",
      "ship_name",
      "ship_mobile",
      "shipAddr",
      "remark",
      "logi_name",
      "ship_no",
      "order_status_text",
      "pay_member_name",
      "pay_status_text",
      "ship_status_text",
      "service_status",
      "payment_type_text",
      "payment_method_name",
      "client_type",
      "nick_name",
      "mobile",
      "city",
      "shop_member_unit_name",
      "shop_member_unit_name_two",
      "shop_member_com_name",
      "shop_member_part_name",
      "receipt_type",
      "receipt_title",
      "receipt_amount",
      "receipt_content",
      "tax_no",
      "reg_addr",
      "reg_tel",
      "bank_name",
      "bank_account"
    ];
    tHeaders = [
      // "交易流水号",
      // "下单人身份类型",
      "订单编号",
      "下单时间",
      "支付时间",
      "商品名称",
      "商品类型",
      "商品结算类型",
      "价格",
      "积分",
      "数量",
      "规格",
      "商品编码",
      "商品总额",
      "商品总积分",
      "订单总额",
      "订单总积分",
      "订单现金抵扣",
      "优惠抵扣金额",
      "收货人",
      "收货人联系方式",
      "收货地址",
      "订单备注",
      "物流公司",
      "物流单号",
      "订单状态",
      "付款人",
      "付款状态",
      "发货状态",
      "售后状态",
      "支付方式",
      "支付渠道",
      "订单来源",
      "员工姓名",
      "手机号",
      "城市",
      "单位名称",
      "二级单位",
      "企业名称",
      "部门名称",
      "发票类型",
      "发票抬头",
      "发票金额",
      "发票内容",
      "税号",
      "注册地址",
      "注册电话",
      "开户银行",
      "银行账号"
    ];
  }

  if (type === 1) {
    orderFunc = "getSellerOrderList";
    filterVals = [
      "name",
      "sku_sn",
      "num",
      "ship_addr",
      "ship_name",
      "ship_mobile",
      "order_sn",
      "order_status_text",
      "logi_name",
      //'ship_status_text',
      "ship_no",
      "member_name"
    ];
    tHeaders = [
      "商品名称以及规格",
      "商品编号",
      "数量",
      "详细地址",
      "姓名",
      "电话",
      "订单号",
      "订单状态",
      "物流公司",
      //'物流状态',
      "物流单号",
      "买家账号"
    ];
  }

  return {
    orderFunc,
    filterVals,
    tHeaders
  };
}

function resetExportData (data, ctx) {
  return data.map((item, index) => {
    item = item || {
      isNull: 0,
      ship_province: "",
      ship_city: "",
      ship_county: "",
      ship_town: "",
      ship_addr: "",
      shipAddr: "",
      sku_list: [
        {
          spec_list: [],
          spec_name: "",
          name: "",
          sku_sn: "",
          service_status: "",
          goods_type: "",
          source: "",
          revise_price: "",
          revise_exchange_money: "",
          num: "",
          revise_exchange_point: ""
        }
      ]
    };
    item.goods_name = item.goods_name.replaceAll('amp;', '').replaceAll(' ', '&nbsp;')
    if (ctx.shopInfo.shop_type !== 2) {
      // 店铺
      if (!item.isNull) {
        item.shipAddr = [
          item.ship_province,
          item.ship_city,
          item.ship_county,
          item.ship_town,
          item.ship_addr
        ].join("");
        item.sku_list.forEach(goods => {
          if (goods.spec_list) {
            goods.spec_name = goods.spec_list
              .map(lab => {
                return lab.spec_name + "：" + lab.spec_value;
              })
              .join("; ");
          }

          if (item.goodnweeess) {
            // item.goodnweeess = item.goodnweeess+' / '+tem.name+x+' '+tem.revise_exchange_money+'x'+tem.num
            return {
              isNull: 1,
              create_time: item.create_time,
              payment_time: item.payment_time,
              // shop_order_price: item.shop_order_price,
              // shop_consume_point: item.shop_consume_point,
              // discount_price: item.discount_price,
              ship_name: item.ship_name,
              ship_mobile: item.ship_mobile,
              shipAddr: item.shipAddr,
              remark: item.remark,
              logi_name: item.logi_name,
              ship_no: item.ship_no,
              pay_member_name: item.pay_member_name,
              pay_status_text: item.pay_status_text,
              ship_status_text: item.ship_status_text,
              payment_type_text: item.payment_type_text,
              payment_method_name: item.payment_method_name,
              client_type: item.client_type,
              nick_name: item.nick_name,
              mobile: item.mobile,
              city: item.city,
              shop_member_com_name: item.shop_member_com_name,
              shop_member_part_name: item.shop_member_part_name,
              // receipt_type: item.receipt_type,
              // receipt_title: item.receipt_title,
              // receipt_amount: item.receipt_amount,
              // receipt_content: item.receipt_content,
              // tax_no: item.tax_no,
              // reg_addr: item.reg_addr,
              // reg_tel: item.reg_tel,
              // bank_name: item.bank_name,
              // bank_account: item.bank_account,
              sn: item.sn,
              order_status_text: item.order_status_text,
              goodnweeess: goods.name,
              sku_sn: goods.sku_sn,
              spec_name: goods.spec_name,
              service_status: ctx.MixinGetOrderStatus(goods.service_status),
              // revise_exchange_money: tem.goods_type == 'POINT' ? tem.revise_exchange_money : tem.revise_price,
              revise_exchange_money:
                goods.goods_type === "POINT"
                  ? goods.source === "CASH"
                    ? goods.revise_price
                    : goods.revise_exchange_money
                  : goods.revise_price,
              num: goods.num,
              // revise_exchange_point: tem.goods_type == 'POINT' ? tem.revise_exchange_point : '',
              revise_exchange_point:
                goods.goods_type === "POINT"
                  ? goods.source === "CASH"
                    ? ""
                    : goods.revise_exchange_point
                  : "",
              jifenType: goods.goods_type === "POINT" ? "积分商品" : "普通商品",
              // spze: tem.goods_type == 'POINT' ? tem.revise_exchange_money : tem.revise_price * tem.num,
              // spzjf: tem.revise_exchange_point * tem.num,
              spze:
                goods.goods_type === "POINT"
                  ? goods.source === "CASH"
                    ? goods.revise_price * goods.num
                    : goods.revise_exchange_money
                  : goods.revise_price * goods.num,
              spzjf:
                goods.source === "CASH"
                  ? ""
                  : goods.revise_exchange_point * goods.num,
              source: goods.source
                ? goods.source === "CASH"
                  ? "现金结算"
                  : "积分结算"
                : goods.goods_type === "NORMAL"
                  ? "现金结算"
                  : "积分结算"
            };
          } else {
            item.goodnweeess = goods.name;
            item.spec_name = goods.spec_name;
            item.sku_sn = goods.sku_sn;
            // item.revise_exchange_money = tem.goods_type == 'POINT' ? tem.revise_exchange_money : tem.revise_price
            item.revise_exchange_money =
              goods.goods_type === "POINT"
                ? goods.source === "CASH"
                  ? goods.revise_price
                  : goods.revise_exchange_money
                : goods.revise_price;

            item.num = goods.num;
            // item.revise_exchange_point = tem.goods_type == 'POINT' ? tem.revise_exchange_point : ''

            item.revise_exchange_point =
              goods.goods_type === "POINT"
                ? goods.source === "CASH"
                  ? ""
                  : goods.revise_exchange_point
                : "";

            item.jifenType =
              goods.goods_type === "POINT" ? "积分商品" : "普通商品";
            // item.spze = tem.goods_type == 'POINT' ? tem.revise_exchange_money : tem.revise_price * tem.num
            // item.spzjf = tem.revise_exchange_point * tem.num

            item.spze =
              goods.goods_type === "POINT"
                ? goods.source === "CASH"
                  ? goods.revise_price * goods.num
                  : goods.revise_exchange_money
                : goods.revise_price * goods.num;

            item.spzjf =
              goods.source === "CASH"
                ? ""
                : goods.revise_exchange_point * goods.num;

            item.service_status = ctx.MixinGetOrderStatus(goods.service_status);
            // item.goodnweeess = tem.name+x+' '+tem.revise_exchange_money+'x'+tem.num

            //之前的数据可能没有这个字段 没有你就根据goodstype判断下 goodstype NORMAL 普通商品 之前普通商品只能通过现金结算 POINT 积分商品  之前积分商品只能通过积分结算
            if (!goods.source) {
              if (goods.goods_type === "NORMAL") item.source = "CASH";
              if (goods.goods_type === "POINT") item.source = "POINT";
            } else {
              item.source = goods.source;
            }

            if (item.source === "CASH") {
              item.source = "现金结算";
            }
            if (item.source === "POINT") {
              item.source = "积分结算";
            }
          }

          //下单人身份
          if (item.shop_member_type === 0) {
            item.shop_member_type = "其他会员";
          }
          if (item.shop_member_type === 1) {
            item.shop_member_type = "员工会员";
          }
          if (item.shop_member_type === 2) {
            item.shop_member_type = "工会会员";
          }
          if (item.shop_member_type === 3) {
            item.shop_member_type = "店主或店员";
          }
        });
      }
    } else {
      // 供应商
      item.spec_json =
        item.spec_json === "null" ? null : JSON.parse(item.spec_json);

      if (item.spec_json) {
        item.spec_name = item.spec_json
          .map(lab => {
            return lab.spec_name + "：" + lab.spec_value;
          })
          .join("; ");
      }

      item.shipAddr = [
        item.ship_province,
        item.ship_city,
        item.ship_county,
        item.ship_town,
        item.ship_addr
      ].join("");
      item.jifenType = item.goods_type === "POINT" ? "积分商品" : "普通商品";
      item.spze = item.price * item.num;
      item.spzjf = item.point * item.num;
      item.service_status = ctx.MixinGetOrderStatus(item.service_status);

      // 之前的数据可能没有这个字段 没有你就根据goodstype判断下 goodstype NORMAL 普通商品 之前普通商品只能通过现金结算 POINT 积分商品  之前积分商品只能通过积分结算
      if (!item.source) {
        if (item.goods_type === "NORMAL") item.source = "CASH";
        if (item.goods_type === "POINT") item.source = "POINT";
      }

      if (item.source === "CASH") {
        item.source = "现金结算";
      }
      if (item.source === "POINT") {
        item.source = "积分结算";
      }

      //下单人身份
      if (item.shop_member_type === 0) {
        item.shop_member_type = "其他会员";
      }
      if (item.shop_member_type === 1) {
        item.shop_member_type = "员工会员";
      }
      if (item.shop_member_type === 2) {
        item.shop_member_type = "工会会员";
      }
      if (item.shop_member_type === 3) {
        item.shop_member_type = "店主或店员";
      }
    }

    item.create_time = Foundation.unixToDate(
      item.create_time,
      "yyyy-MM-dd hh:mm:ss"
    );

    item.payment_time = Foundation.unixToDate(
      item.payment_time,
      "yyyy-MM-dd hh:mm:ss"
    );

    return item;
  });
}

function getExportMetadata (type) {
  let orderFunc, filterVals, tHeaders;
  switch (type) {
    case 1:
      orderFunc = "getSellerOrderList";
      filterVals = [
        "name",
        "sku_sn",
        "num",
        "ship_addr",
        "ship_name",
        "ship_mobile",
        "order_sn",
        "order_status_text",
        "logi_name",
        //"ship_status_text",
        "ship_no",
        "member_name"
      ];
      tHeaders = [
        "商品名称以及规格",
        "商品编号",
        "数量",
        "详细地址",
        "姓名",
        "电话",
        "订单号",
        "订单状态",
        "物流公司",
        //"物流状态",
        "物流单号",
        "买家账号"
      ];
      break;
    case 2:
      filterVals = [
        "goods_name",
        "goods_sn",
        "ship_num",
        // "ship_addr",
        // "ship_name",
        // "ship_mobile",
        "order_sn",
        // "order_status_text",
        "logi_name",
        //"ship_status_text",
        "delivery_no",
        "remark"
      ];
      tHeaders = [
        "商品名称",
        "商品编号",
        "数量",
        // "详细地址",
        // "姓名",
        // "电话",
        "订单号",
        // "订单状态",
        "物流公司",
        //"物流状态",
        "物流单号",
        "错误原因"
      ];
  }

  return {
    orderFunc,
    filterVals,
    tHeaders
  };
}
</script>

<style lang="scss" scoped>
@media screen and (max-width: 1545px) {
  .restyle1545 {
    // margin-top: 12px;
  }
}

.restyle1545 {
  width: 410px;
}

/deep/ {
  .el-tabs__header {
    margin-bottom: 0;
  }

  .goods-cover-wrapper .cell {
    padding-left: 0 !important;
  }
}

.conceal {
  white-space: nowrap; //不换行
  overflow: hidden; //超出则隐藏
}

/deep/.import-order-dialog {
  .el-dialog {
    min-width: 1024px;
    margin-top: 10vh !important;
  }

  .el-dialog__body {
    max-height: 65vh;
    overflow-y: scroll;
  }
}

/deep/.el-loading-spinner {
  .el-icon-loading,
  .el-loading-text {
    color: white;
    font-size: 16px;
  }
}

.remark-box {
  max-width: 150px;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.order_sn_style {
  color: red;
  font-size: 12px
}
</style>
